import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

export interface IActionButton {
  icon: string;
  class?: string;
  disabled?: boolean;
  tooltip?: string;
  onClick: () => void;
};

@Component({
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, TranslateModule],
  selector: 'app-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Input() highlighted: boolean = false;
  @Input() separator: boolean = false;
  @Input() bottomPadding: boolean = false;
  @Input() actionButtons: IActionButton[] = [];
  @Input() showCloseButton: boolean = true;
  @Output() close: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  onClose() {
    this.close.emit();
  }

}
