

<div class="modal-header"
  [class.modal-header--highlighted]="highlighted"
  [class.modal-header--bottom-padding]="bottomPadding"
  [class.modal-header--bottom-separator]="separator">
  
  <div class="left-content">
    <h5 class="modal-breadcrumb">
      <ng-content select="[slot=breadcrumb]">

      </ng-content>
    </h5>

    <h3 class="modal-title">
      <ng-content select="[slot=title]">

      </ng-content>
    </h3>

    <h5 class="modal-subtitle">
      <ng-content select="[slot=subtitle]">

      </ng-content>
    </h5>

    <ng-content></ng-content>
  </div>


  <div class="right-content flex column-gap-2 align-items-center align-self-baseline">
    <ng-content select="[slot=actions]">

    </ng-content>

    @for (btn of actionButtons; track actionButtons) {
      <a
        (click)="btn.disabled ? null : btn.onClick()"
        [ngbTooltip]="btn.tooltip ? (btn.tooltip | translate) : null"
        ngbAutofocus
        pRipple
        [class.cursor-pointer]="!btn.disabled"
        class="flex action-button align-self-baseline align-items-center hover:surface-100 font-medium border-circle transition-colors transition-duration-150 {{ btn.class || '' }}">
        <i [class]="btn.icon">
        </i>
      </a>
    }

    @if (showCloseButton) {
      <a
        (click)="onClose()"
        ngbAutofocus
        pRipple
        class="flex action-button align-self-baseline align-items-center surface-50 hover:surface-100 font-medium border-circle cursor-pointer transition-colors transition-duration-150">
        <i class="pi pi-times">
        </i>
      </a>
    }
  </div>

  <div class="under-content">
    <ng-content select="[slot=undercontent]">

    </ng-content>
  </div>

</div>
